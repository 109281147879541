import React, { MutableRefObject, Dispatch, SetStateAction } from 'react';
import Image from 'next/legacy/image';
import { Icon } from '~/components/common/Icon';
//import { HeatwaveGradient } from '~/components/common/HeatwaveGradient';
import { getTailwindColor } from '~/utils/style/tailwind';
import Link from 'next/link';

interface MobileHeaderProps {
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
  toggleMenu?: () => void;
  isMenuOpen: boolean;
  pageRef: MutableRefObject<null | HTMLElement>;
}

const MobileHeader: React.FC<MobileHeaderProps> = ({
  setIsMenuOpen,
  toggleMenu,
  isMenuOpen,
  pageRef,
}) => {
  return (
    <div className='fixed top-0 inset-x-0 border-b-2 py-3 px-8 border-heatwave6/50 flex justify-between items-center z-[50] backdrop-blur-[50px]'>
      <div className='absolute h-full w-full left-0 top-0 bg-checkered bg-[length:4px_4px] opacity-[0.04] [background-position:0_0,_2px_2px] pointer-events-none z-0' />
      <Link href='/' className='mt-1.5 mr-1 -translate-x-3 translate-y-0.5' legacyBehavior>
        <div
          className='flex items-center justify-center'
          onClick={() => {
            setIsMenuOpen(false);
            pageRef.current?.scrollTo(0, 0);
          }}
        >
          <Image
            width={180}
            height={45}
            src='/common/brand/logo/logo_text.svg'
            alt='logo'
            priority={true}
          />
        </div>
      </Link>
      <div
        className={`scale-[1.5] ${isMenuOpen && 'drop-shadow-[0px_0px_8px_rgba(94,252,214,1)]'}`}
        onClick={toggleMenu}
      >
        <Icon
          name={isMenuOpen ? 'cross' : 'menu'}
          color={isMenuOpen ? getTailwindColor('coldsnap4') : '#fff'}
        />
      </div>
    </div>
  );
};

export default MobileHeader;
