import React from 'react';
import { ClickAwayListener, SxProps } from '@mui/material';
import { Box } from '@mui/material';
import Image from 'next/legacy/image';
import { Icon } from '~/components/common/Icon';
import { getTailwindColor } from '~/utils/style/tailwind';
import { animated, easings, useTransition } from '@react-spring/web';
import { useRouter } from 'next/router';

interface BlogPopoverProps {
  blogData: Array<{ title: string; slug: string }>;
}

const BlogPopover: React.FC<BlogPopoverProps> = ({ blogData }) => {
  const { pathname } = useRouter();
  const [open, setOpen] = React.useState(false);

  const popoverAnimation = useTransition(open, {
    from: { opacity: 0, top: '-25%' },
    enter: { opacity: 1, top: '-4%' },
    leave: { opacity: 0, top: '-25%' },
    config: {
      duration: 250,
      easing: easings.easeInCubic,
    },
  });

  const handleClick = () => {
    if (pathname !== '/blog') {
      setOpen((prev) => !prev);
    }
  };

  const handleClickAway = () => {
    setOpen(false);
  };
  const styles: SxProps = {
    position: 'absolute',
    top: 50,
    right: 0,
    transform: 'translateX(-50%)',
    left: '50%',
    zIndex: 1,
    color: 'white',
    width: '420px',
    overflow: 'hidden',
    borderRadius: '20px',
  };
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{ position: 'relative' }}>
        <div onClick={handleClick} className='relative h-fit w-fit'>
          <div className='relative'>
            <div
              className={`text-white p-2.5 text-lg leading-[100%] drop-shadow-[0_4px_4px_rgba(0,0,0,0.45)] hover:cursor-pointer space-grostek-regular font-medium text-center hover:text-coldsnap4 transition-colors hover:ring-2 hover:ring-heatwave7/10 rounded-lg overflow-hidden  ${
                pathname.startsWith('/blog') ? 'bg-heatwave7/20 z-[1]' : 'bg-transparent'
              } `}
            >
              <span
                className={`${
                  pathname.startsWith('/blog') ? '[text-shadow:_0px_-1px_2px_rgb(0_0_0_/_18%)]' : ''
                } z-[10] relative whitespace-nowrap`}
              >
                Blog
              </span>
              <div
                className={`absolute h-[70px] w-[120px] scale-y-[2] scale-x-[1.1] -left-5 top-1/2  overflow-hidden ${
                  pathname.startsWith('/blog') ? 'inline' : 'hidden'
                }`}
              >
                <Image
                  src='/assets/landingB2BAug23/gradients/menu_gradient.webp'
                  alt='gradient'
                  height={70}
                  width={120}
                />
              </div>
            </div>
            <div
              className={`pointer-events-none absolute h-fit w-fit bottom-[5px] z-[72] left-[45%] scale-[2]  -translate-x-[50%] transition-all ease-in-out duration-[250ms] drop-shadow-[0px_0px_4px_rgba(94,252,214,1)] ${
                pathname.startsWith('/blog') ? 'opacity-100' : 'opacity-0 -bottom-[55%]'
              } `}
            >
              <div className='[&>div]:absolute'>
                <Icon name='menu_diamond' color='coldsnap4' size='small' />
                <div className='[&>div]:absolute scale-[0.6] top-[1px] left-[1px]'>
                  <Icon name='menu_diamond' color='#B4FEEC' size='small' />
                </div>
              </div>
            </div>
          </div>
        </div>
        <>
          {popoverAnimation((style, isOpen: boolean) => (
            <>
              {isOpen && (
                <animated.div style={style} className='absolute left-[40%] -translate-x-[60%]'>
                  <div className='absolute h-fit w-fit left-[35%] top-[2.75rem] scale-x-[1.05]'>
                    <Icon name='popoverArrow' color={getTailwindColor('heatwave6')} />
                  </div>
                  <Box className='popover-background' sx={styles}>
                    <div className='flex flex-col relative z-20 py-[10px] px-[20px]'>
                      <div className='absolute h-full w-full top-0 left-0 blur-[20px]' />
                      <div className='flex w-full justify-between items-center mt-[0.68rem] mb-[0.85rem]'>
                        <div className='uppercase space-grotesk-bold text-[16px] font-bold leading-[100%] [text-shadow:_0px_2px_2px_rgb(15_16_47_/_25%)] text-night0'>
                          Top Articles
                        </div>
                        <div className='hover:cursor-pointer group translate-x-2.5 -translate-y-0.5'>
                          <a href={`/blog`}>
                            <div className='flex items-center justify-center gap-x-2'>
                              <span className='text-coldsnap4 space-mono-regular [text-shadow:_0_1px_1px_rgb(15_16_47_/_30%)] text-[14px] leading-[20px]'>
                                See all
                              </span>
                              <span className='rotate-90 scale-[0.5] group-hover:translate-x-0.5 transition-all ease-in-out duration-200'>
                                <Icon name='chevronUp' color={getTailwindColor('coldsnap4')} />
                              </span>
                            </div>
                          </a>
                        </div>
                      </div>
                      {blogData?.map((item: { title: string; slug: string }, index: number) => {
                        return (
                          <div className='relative w-full' key={index} onClick={handleClickAway}>
                            <div className='absolute w-full h-[2px] top-0 left-0 popover-divider-background' />
                            <div className='hover:cursor-pointer'>
                              <a className='hover:cursor-pointer' href={`/blog/${item.slug}`}>
                                <div
                                  className='my-[0.9rem] text-night0 hover:text-coldsnap4 [text-shadow:_0px_2px_2px_rgba(15_16_47_/_25%)] space-grotesk-regular text-[16px] font-semibold leading-[16px]'
                                  key={index}
                                >
                                  {item.title}
                                </div>
                              </a>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Box>
                </animated.div>
              )}
            </>
          ))}
        </>
      </Box>
    </ClickAwayListener>
  );
};

export default BlogPopover;
