import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { IS_LOCAL_ENV } from '~/utils/constants';
import Header from '../sections/Header/Header';
import MobileMenu from './MobileMenu';

type PageLayoutType = {
  children: React.ReactElement | Array<React.ReactElement>;
  blogData: Array<{ title: string; slug: string }>;
};

const PageLayout: React.FC<PageLayoutType> = ({ children, blogData }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const toggleMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);
  const pageRef = useRef(null);
  const router = useRouter();
  const [isMounted, setIsMounted] = useState<boolean>(false);

  useEffect(() => {
    let interval: any;
    if (IS_LOCAL_ENV) {
      interval = setInterval(() => {
        if (pageRef.current) {
          setIsMounted(true);
          clearInterval(interval);
        }
      }, 100);
    } else {
      setIsMounted(true);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, []);

  // scroll-smooth doesnt work when applied to the div element, so adding this use effect to add smooth scrolling to the html element when this component is mounted, and removing when it is unmounted.
  useEffect(() => {
    const htmlElement = document.documentElement;
    // only add snooth scrolling to the root page
    if (router.pathname === '/') {
      htmlElement.classList.add('scroll-smooth');
    }
    return () => {
      htmlElement.classList.remove('scroll-smooth');
    };
  });

  return (
    <div
      ref={pageRef}
      className='relative w-full min-h-screen overflow-y-clip max-w-full pt-24 lg:px-2 overflow-x-clip mx-auto bg-night5 z-0 hide-scrollbar scroll-mt-[5rem] landing-cursor'
    >
      {/* <div className='absolute h-full w-full top-0 left-0 bg-[rgba(0,0,0,0.02)] blur-[50px] z-[1]' /> */}
      <div className='absolute inset-0 bg-checkered bg-[length:4px_4px] opacity-[0.04] [background-position:0_0,_2px_2px] pointer-events-none z-0' />

      {/* Frame */}
      <div className='relative pointer-events-none hidden lg:flex'>
        <div className='fixed inset-0 p-2 flex items-center justify-center z-50'>
          <div className='w-full h-full  border-1 border-heatwave1/40 lg:rounded-[1.125rem] max-w-full' />
        </div>

        {/* Top Bottom Covers */}
        <div className='fixed inset-x-0 h-2 top-0 bg-night5 z-40 flex justify-center items-center'>
          <div className='w-full max-w-[2400px] flex justify-between'>
            <div className='h-8 w-5 transform rotate-45 bg-night5' />
            <div className='h-8 w-5 transform -rotate-45 bg-night5' />
          </div>
        </div>
        <div className='fixed inset-x-0 h-2 bottom-0 bg-night5 z-40 flex justify-center items-center'>
          <div className='w-full max-w-[2400px] flex justify-between'>
            <div className='h-8 w-5 transform -rotate-45 bg-night5' />
            <div className='h-8 w-5 transform rotate-45 bg-night5' />
          </div>
        </div>
        {/* Left Right Covers */}
        <div className='fixed inset-y-0 w-2 right-0 bg-night5 z-40' />
        <div className='fixed inset-y-0 w-2 left-0 bg-night5 z-40' />
      </div>
      {isMounted && (
        <Header
          blogData={blogData}
          toggleMenu={toggleMenu}
          isMenuOpen={isMobileMenuOpen}
          setIsMenuOpen={setIsMobileMenuOpen}
          pageRef={pageRef}
        />
      )}
      {children}
      <MobileMenu pageRef={pageRef} isOpen={isMobileMenuOpen} setIsOpen={setIsMobileMenuOpen} />
    </div>
  );
};

export default PageLayout;
