import React, { Dispatch, MutableRefObject, SetStateAction } from 'react';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

type HeaderProps = {
  toggleMenu: () => void;
  isMenuOpen: boolean;
  pageRef: MutableRefObject<null | HTMLElement>;
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
  blogData: Array<{ title: string; slug: string }>;
};

const Header: React.FC<HeaderProps> = ({
  toggleMenu,
  setIsMenuOpen,
  isMenuOpen,
  pageRef,
  blogData,
}) => {
  return (
    <>
      <div className='hidden lg:inline'>
        <DesktopHeader blogData={blogData} />
      </div>

      <div className='lg:hidden'>
        <MobileHeader
          setIsMenuOpen={setIsMenuOpen}
          pageRef={pageRef}
          toggleMenu={toggleMenu}
          isMenuOpen={isMenuOpen}
        />
      </div>
    </>
  );
};

export default Header;
