import { getCookie } from 'cookies-next';
import Link from 'next/link';
import React from 'react';
import { AnalyticsClientSingleton } from '~/clients/AnalyticsClient';
import { CalloutButton } from '~/components/common/Button';
import { useTailwindBreakpoints } from '~/hooks/ui/useTailwindBreakpoints';

interface CTAProps {
  maxWidthOverride?: string;
  buttonOverride?: string;
}

export const CTA: React.FC<CTAProps> = ({ maxWidthOverride, buttonOverride }) => {
  const { md } = useTailwindBreakpoints();

  return (
    <div
      className={`flex flex-col items-center max-w-lg w-full ${
        maxWidthOverride ? maxWidthOverride : 'lg:max-w-[23.875rem]'
      } `}
    >
      <Link
        prefetch={false}
        href={getCookie('privy:token') ? '/dashboard' : '/login'}
        className='w-full'
        legacyBehavior
      >
        <CalloutButton
          text='PLAY NOW'
          onClick={() => {
            AnalyticsClientSingleton.getInstance().callToActionClicked();
          }}
          overrides={buttonOverride ? buttonOverride : md ? 'py-[1.2rem]' : 'py-4'}
          fontSize={md ? 'large' : 'normal'}
        />
      </Link>
    </div>
  );
};
