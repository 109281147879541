import Image from 'next/legacy/image';
import React, { Dispatch, MutableRefObject, SetStateAction, useEffect } from 'react';
import { Icon } from '~/components/common/Icon';
import { useTailwindBreakpoints } from '~/hooks/ui/useTailwindBreakpoints';
import useListenUrlHash from '../hooks/useListenUrlHash';
import { FAQ_SECTION, FEATURES, THE_GAMES } from '../sectionNames';
import MobileHeader from '../sections/Header/MobileHeader';
import { CTA } from './CTA';
import Link from 'next/link';
import { useRouter } from 'next/router';

interface MobileMenuProps {
  isOpen: boolean;
  pageRef: MutableRefObject<null | HTMLElement>;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const MobileMenu: React.FC<MobileMenuProps> = ({ isOpen, setIsOpen, pageRef }) => {
  const { lg } = useTailwindBreakpoints();
  const currentHash = useListenUrlHash();
  const { pathname } = useRouter();

  const handleSectionClicked = () => {
    setIsOpen(false);
  };

  const enableScroll = () => (document.body.style.overflow = 'auto');
  const disableScroll = () => (document.body.style.overflow = 'hidden');

  useEffect(() => {
    if (isOpen) {
      disableScroll();
    } else {
      enableScroll();
    }

    return () => {
      enableScroll();
    };
  }, [isOpen]);

  useEffect(() => {
    if (lg) {
      setIsOpen(false);
      enableScroll();
    }
  }, [lg, setIsOpen]);

  const toggleMenu = () => setIsOpen((isOpen) => !isOpen);

  const navItems = [
    { href: `/#${FEATURES}`, text: 'Features', hash: FEATURES },
    { href: `/#${THE_GAMES}`, text: 'Games', hash: THE_GAMES },
    { href: `/#${FAQ_SECTION}`, text: 'FAQ', hash: FAQ_SECTION },
  ];

  const renderNavItem = (item: { href: string; text: string; hash: string }) => (
    <a
      key={item.text}
      href={item.href}
      className='relative h-fit w-fit mt-[0.5rem]'
      onClick={handleSectionClicked}
    >
      <div
        className={`text-[2rem] h-fit w-fit relative space-grotesk-regular px-[1.875rem] py-[0.938rem] font-bold leading-[2rem] rounded-lg [text-shadow:_0px_2px_2px_rgb(15_16_47_/_25%)] text-white overflow-hidden ${
          currentHash === item.hash ? 'bg-heatwave7/20 z-[1]' : 'bg-transparent'
        } `}
      >
        <span
          className={`${
            currentHash === item.hash ? '[text-shadow:_0px_-1px_2px_rgb(0_0_0_/_18%)]' : ''
          } z-[10] relative`}
        >
          {item.text}
        </span>
        <div
          className={`absolute h-full w-full scale-y-[2] scale-x-[1.1] left-0 top-1/2  overflow-hidden transition-all ease-in-out duration-[250ms] ${
            currentHash === item.hash ? 'opacity-100' : 'opacity-0'
          }`}
        >
          <Image
            src='/assets/landingB2BAug23/gradients/menu_gradient.webp'
            alt='gradient'
            height={140}
            width={240}
          />
        </div>
      </div>
      <div
        className={`absolute pointer-events-none h-fit w-fit bottom-[3px] z-[72] left-[47%] scale-[1.5]  -translate-x-[50%] transition-all ease-in-out duration-[250ms] drop-shadow-[0px_0px_4px_rgba(94,252,214,1)] ${
          currentHash === item.hash ? 'opacity-100' : 'opacity-0 -bottom-[55%]'
        } `}
      >
        <div className='[&>div]:absolute'>
          <Icon name='menu_diamond' color='coldsnap4' size='small' />
          <div className='[&>div]:absolute scale-[0.6] top-[1px] left-[1px]'>
            <Icon name='menu_diamond' color='#B4FEEC' size='small' />
          </div>
        </div>
      </div>
    </a>
  );

  return (
    <div
      className={`fixed inset-0 top-0  backdrop-blur-[18px] bg-night8/70  ${
        isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
      } z-50 transition-all ease-in-out duration-100 flex flex-col`}
    >
      <MobileHeader
        setIsMenuOpen={setIsOpen}
        isMenuOpen={isOpen}
        pageRef={pageRef}
        toggleMenu={toggleMenu}
      />

      <div className='relative mt-32 mb-2 h-full flex flex-col overflow-y-auto '>
        <div className='flex flex-col flex-1 items-center mt-[0.75rem]'>
          {navItems.map(renderNavItem)}

          <Link href='/blog' legacyBehavior>
            <div className='relative h-fit w-fit my-[0.65rem]'>
              <div
                className={`text-white p-2.5 text-[2rem] leading-[100%] [text-shadow:_0px_2px_2px_rgb(15_16_47_/_25%)] hover:cursor-pointer space-grostek-regular font-medium text-center hover:text-coldsnap4 hover:ring-2 hover:ring-heatwave7/10 rounded-lg overflow-hidden relative  ${
                  pathname.startsWith('/blog') ? 'bg-heatwave7/20 z-[1]' : 'bg-transparent'
                } `}
              >
                <span
                  className={`${
                    pathname.startsWith('/blog')
                      ? '[text-shadow:_0px_-1px_2px_rgb(0_0_0_/_18%)]'
                      : ''
                  } z-[10] relative`}
                >
                  Blog
                </span>
                <div
                  className={`absolute h-full w-full scale-y-[2] scale-x-[1.1] left-0 top-1/2  overflow-hidden transition-all ease-in-out duration-[250ms] ${
                    pathname.startsWith('/blog') ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  <Image
                    src='/assets/landingB2BAug23/gradients/menu_gradient.webp'
                    alt='gradient'
                    layout='fill'
                    priority={true}
                  />
                </div>
              </div>
              <div
                className={`absolute h-fit w-fit bottom-[5px] z-[72] left-[45%] scale-[2]  -translate-x-[50%] transition-all ease-in-out duration-[250ms] drop-shadow-[0px_0px_4px_rgba(94,252,214,1)] ${
                  pathname.startsWith('/blog') ? 'opacity-100' : 'opacity-0 -bottom-[55%]'
                } `}
              >
                <div className='[&>div]:absolute'>
                  <Icon name='menu_diamond' color='coldsnap4' size='small' />
                  <div className='[&>div]:absolute scale-[0.6] top-[1px] left-[1px]'>
                    <Icon name='menu_diamond' color='#B4FEEC' size='small' />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div className='w-[90%] mx-auto flex justify-center mb-2 gap-y-3'>
          <CTA />
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
