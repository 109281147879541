import { useEffect, useMemo, useState } from 'react';
import resolveConfig from 'tailwindcss/resolveConfig';
// @ts-ignore
import config from '../../tailwind.config.js';
import debounce from 'lodash/debounce';

const twConfig = resolveConfig(config);
const screens = twConfig.theme?.screens as Record<string, string>;

// Calculate the parsed breakpoints once outside the hook
const parsedBreakpoints = {
  xs: parseInt(screens.xs),
  mobile: parseInt(screens.mobile),
  sm: parseInt(screens.sm),
  tablet: parseInt(screens.tablet),
  md: parseInt(screens.md),
  lg: parseInt(screens.lg),
  xl: parseInt(screens.xl),
  two_xl: parseInt(screens['2xl']),
  three_xl: parseInt(screens['3xl']),
};

export const useTailwindBreakpoints = () => {
  const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);

  const debouncedHandleResize = useMemo(
    () =>
      debounce(() => {
        setWidth(window.innerWidth);
      }, 100),
    []
  );

  useEffect(() => {
    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [debouncedHandleResize]);

  useEffect(() => {
    return () => {
      debouncedHandleResize.cancel();
    };
  }, [debouncedHandleResize]);

  return {
    xs: width >= parsedBreakpoints.xs,
    mobile: width >= parsedBreakpoints.mobile,
    sm: width >= parsedBreakpoints.sm,
    tablet: width >= parsedBreakpoints.tablet,
    md: width >= parsedBreakpoints.md,
    lg: width >= parsedBreakpoints.lg,
    xl: width >= parsedBreakpoints.xl,
    two_xl: width >= parsedBreakpoints.two_xl,
    three_xl: width >= parsedBreakpoints.three_xl,
    isDesktop: width >= parsedBreakpoints.xl,
  };
};
