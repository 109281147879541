import { useState, useEffect } from 'react';

const useListenUrlHash = () => {
  const [currentHash, setCurrentHash] = useState<string>();

  useEffect(() => {
    const handleHashChange = () => {
      setCurrentHash(window.location.hash.substring(1));
    };
    window.addEventListener('customHashChanged', handleHashChange);
    return () => {
      window.removeEventListener('customHashChanged', handleHashChange);
    };
  }, [setCurrentHash]);

  return currentHash;
};

export default useListenUrlHash;
